
// Fonts
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

//CUSTOM VARIABLES
$csbc-blue: #0070b9;
$screen-xl-min: 1500px;
$screen-lg-min: 1200px;
$screen-md-min: 1024px;
$screen-sm-min: 768px;

//LOGO
div.logo {
	position: absolute;
	z-index: 1;
	top: 0;
	img {
		width: 100%;
	}
}

//NAV
.navbar {
	border-radius: 0 0 14px 14px;
	margin-bottom: 0;
}

//MAIN SLIDER
div.mainslider {
	background-color: #ddd; 
	width: 100%;
	margin-top: -10px;
	img {
		width: 100%;
	}
}

//COUNTDOWN
div.countdown {
	background-color: darken($csbc-blue, 10%);
	color: #fff;
	border-radius: 0  0 6px 6px;
	margin-bottom: 20px;
	div#days, div#hours, div#minutes, div#seconds {
		display: inline-block;
		vertical-align: middle;
		margin: 2.5rem;
		font-size: 2rem;
		font-weight: bold;
	}
	div#days::after {
		content: ' jours';
	}
	div#hours::after {
		content: ' heures';
	}
	div#minutes::after {
		content: ' minutes';
	}
	div#seconds::after {
		content: ' secondes';
	}
}

//FOOTER
footer {
	background-color: $csbc-blue;
	color: white;
	padding: 2rem 5rem;
	margin-top: 11px;
	img {
		width: 100%;
	}
	.footer_logos img {
		width: auto;
		max-height: 80px;
	}
}

//NOTIFY ME
.notify-me {
	padding: 2rem 0.75rem;
}

//MAILCHIMP
#mc_embed_signup {
	input.email {width:100% !important;}
	input.button {width:100% !important;}
}

//TESTS
div.question {
	margin: 2.5rem 0;
	padding: 1.5rem;
	border-bottom: 1px solid #ccc;
	div.question-block {
		display: inline-block;
		vertical-align: top;
		width: 49%;
		ol {
			list-style-type: lower-alpha;
			li {
				margin: 1rem 0;
			}
		}
		input, textarea, select {
			border: 1px solid rgba($csbc-blue,0.75);
			margin: 3px 0;
		}
		textarea, select {
			width: 100%;
		}
	}
	div.answer-block {
		display: inline-block;
		vertical-align: top;
		width: 45%;
		margin-left: 3rem;
		visibility: hidden;
		border-radius: 15px;
		padding: 1.5rem;
	}
	
}
#complete {
	visibility: hidden;
}

//////MEDIA QUERIES

//LARGE DESKTOP
@media all and (min-width: $screen-xl-min) {
	//LOGO
	$logo-width: 100vw/12;
	div.logo {
		left: (50 - ($logo-width/2));
		width: $logo-width;
	}
	//NAV
	.navbar {
		padding: 4rem 2rem;
	}	
}
//DESKTOP
@media all and (max-width: $screen-xl-min) and (min-width: $screen-md-min) {
	//LOGO
	$logo-width: 100vw/9;
	div.logo {
		left: (50 - ($logo-width/2));
		width: $logo-width;
	}
	//NAV
	.navbar {
		padding: 4rem 2rem;
	}	
}
//SMALL DESKTOP
@media all and (max-width: $screen-md-min) and (min-width: $screen-sm-min) {
	//LOGO
	$logo-width: 100vw/8;
	div.logo {
		left: (50 - ($logo-width/2));
		width: $logo-width;
	}
	//NAV
	.navbar {
		padding: 3rem 2rem;
	}
}
//TABLETS
@media all and (max-width: $screen-sm-min) {
	//LOGO
	$logo-width: 100vw/6;
	div.logo {
		left: (50 - ($logo-width/2));
		width: $logo-width;
	}
	//NAV
	.navbar {
		padding: 3.5rem 2rem;
	}
}

//////HELPER CLASSES
.underline {
	text-decoration: underline;
}